import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
class Social extends Component {
    render() { 
        return (
            <div>
               <ul class="ct-socials">
                    <li>
                        <a href="https://www.instagram.com/iamnikhilnanda" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram}/></a>
                    </li>
                    <li>
                        <a href="https://www.facebook.com/nikhilnandaofficial" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFacebook}/></a>
                    </li>
                    <li>
                        <a href="https://twitter.com/iamnikhilnanda" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitter}/></a>
                    </li>
                    <li>
                        <a href="https://www.linkedin.com/in/nikhil-nanda-734a643" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin}/></a>
                    </li>
                </ul>
 
            </div>
        )
    }
}

export default Social
