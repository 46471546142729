import React from "react";
import { Carousel } from "3d-react-carousal";
//import Photo from "../Assets/PHIL3.JPG";
import slide6 from "../Assets/6-min.png"
import slide2 from "../Assets/2-min.png"
import slide3 from "../Assets/3-min.png"
import slide4 from "../Assets/4-min.png"
import slide5 from "../Assets/5-min.png"

const Section6 = () => {
  let slides = [
    <img className="slide-img" src={slide2} alt="2" />,
    <img className="slide-img" src={slide3} alt="3" />,
    <img className="slide-img" src={slide4} alt="4" />,
    <img className="slide-img" src={slide6} alt="6" />,
    <img className="slide-img" src={slide5} alt="5" />,
    // <img
    //   className="slide-img"
    //   src="https://picsum.photos/800/303/?random"
    //   alt="4"
    // />,
    // <img
    //   className="slide-img"
    //   src="https://picsum.photos/800/304/?random"
    //   alt="5"
    // />,
  ];

  return (
    <div>
      <div className="section-top">
        <h4 className="section-top-heading">A peek into my life</h4>
        {/* <div style={{ color: "#2c3e50" }}>A peek into my life</div> */}
      </div>
      <div>
        <Carousel slides={slides} autoplay={true} interval={3000} />
      </div>
    </div>
  );
};

export default Section6;
