import React from "react";
import { Link } from "react-router-dom";
import WhoAmI from "../Assets/WhoAmI.png";
import Slide from "react-reveal/Slide";
import { Link as SLink } from "react-scroll";

const Section3 = () => {
  return (
    <div id="whoami">
      <div className="section3 row">
        <div className="col-12 col-sm-6 section3-left">
          {/* <img src={Dot} alt="" /> */}
          <Slide bottom>
          <div className="section-top1">
        <h4 className="section-top-heading" s>WHO AM I?</h4>
        <div style={{ color: "grey" }}>
        <br></br>A first-generation Entrepreneur, Industrialist, Fitness Enthusiast and
          Philanthropist.<br></br>I am rooted in the nation's soil and strive hard to live life as per a value system derived from sanskars based in Sanatan Dharm.
        </div>
      </div>
            <div className="section3-heading">Nikhil Nanda</div>

            <div className="section3-text">
              Nationalism and spiritualism form the core of my value system.
              I am standing firm in my journey of building an enterprise that
              intertwines with the Nation First Philosophy.
              I believe that staying true to your roots
              is key to driving innovation from idea to scale.
            </div>

            <div className="section3-lower">
              <SLink to="blogs" smooth={true} duration={2000}>
                <button className="section3-button">My Blogs</button>
              </SLink>
              <Link to="/contact" smooth={true} duration={2000}>
                <button className="section3-button">Contact</button>
              </Link>
            </div>
          </Slide>
        </div>
        <div className="col-sm-6 text-right">
          <img
            id="section3-img"
            style={{ height: "90%", width: "85%" }}
            src={WhoAmI}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Section3;
