import React, { useEffect } from "react";
import PageSection from "../SubComponents/PageSectionRight";
import FitnessImg from "../Assets/fit11.jpg";

import ReactPlayer from "react-player";
const Fitness = () => {
  const description = `Exercising is what truly makes age just a number. When you start seeing a difference in the body, suddenly all your life's happiness starts to revolve around your fitness regime. For me, my fitness gives me great pleasure, and I have been an enthusiast since my college days.`;
  const description2 = `
 
  
 ### It's the Journey that matters.
 
  I set up mini goals with the bigger picture at the back of my mind. These goals, when achieved, serve as an added motivation to guide me in my path to become a better me. Goals keep you steadfast, and before you realise you have developed a champion mindset that benefits you in every sphere of life. I enjoy the daily journey and cherish every grunt that accompanies rigorous exercises that I perform daily.
  `;

  const description3 = `
  ### A man of habits is a successful man
 Routine is what makes this chaotic life an experience worth reliving ever day. I found the gym and exercising as the fastest way to develop healthy habits, not just for fitness but across all aspects of my life. Every sweatdrop has been a source of inspiration for me to do better in everything I venture into. 
  
  To be a man of routine sometimes can get tough for reasons like family, friends and work. I have always overcome any barrier that has shown up as I believe that if you can't stand true to the commitments you make to yourself, you won't stand true for the commitments towards your friends, family, society and nation.
  `;
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <React.Fragment>
      <div className="section-top-right">
        <h4 className="section-top-heading">FITNESS ENTHUSIAST</h4>
        <div style={{ color: "#2c3e50" }}>
          "One can’t achieve anything in life if health isn't on your side. Fitness is the core to achieving discipline, success and a holistic wellbeing. Exercising is what truly makes age just a number. For me, my fitness gives me great pleasure, and I have been an enthusiast since my college days."
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <ReactPlayer
            url="https://firebasestorage.googleapis.com/v0/b/nikhil-nanda.appspot.com/o/fitness.mp4?alt=media&token=2f0efbef-3a2e-4223-89b8-3097d9b38cd3"
            width="100%"
            style={{ margin: "0px 20px 20px 20px" }}
            playing={true} //autoplay
            controls
          />
          <PageSection description={description3} />
          {/* <img src={FitnessImg} alt="Fitness" className="InsideImagesLeft" /> */}
        </div>
        <div className="col-12 col-sm-6">
          <PageSection description={description} />
          <img src={FitnessImg} alt="Fitness" className="InsideImagesLeft2" />
          <PageSection description={description2} />
        </div>
        {/* <ReactPlayer
          url="https://firebasestorage.googleapis.com/v0/b/nikhil-nanda.appspot.com/o/fitness.mp4?alt=media&token=2f0efbef-3a2e-4223-89b8-3097d9b38cd3"
          width="100%"
          style={{ margin: "40px" }}
          playing={true} //autoplay
          controls
        /> */}
      </div>
    </React.Fragment>
  );
};

export default Fitness;
