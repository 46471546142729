import React from "react";
import { Link } from "react-router-dom";

import Slide from "react-reveal/Slide";

const Section9 = () => {
  return (
    <div id="contact">
      <div className="section-top">
        <h4 className="section-top-heading">Write to me</h4>
        <div style={{ color: "#2c3e50" }}>
          Contact me with your query, concern or thought and I will be happy to
          write back!
        </div>
      </div>
      {/* <div className="row section9">
        <Slide bottom>
          <div className="col-3"></div>
          <div className="col-4 col-sm-6 section9-left-content">
            <div className="section9-text">
              Get in touch,
              <br /> we would love to
              <br /> hear from you.
            </div>
            <Link to="/contact">
              <button className="section9-button">CONTACT US</button>
            </Link>
          </div>
        </Slide>
      </div>  */}
      
      <div className="row section9">
        <Slide bottom>
          <div className="col-12 col-sm-6 section9-left-content">
            <div className="section9-text">
              Get in touch,
              <br /> we would love to
              <br /> hear from you.
            </div>
            <Link to="/contact">
              <button className="section9-button">CONTACT US</button>
            </Link>
            {/* <div>{<Link to="/">Write to us</Link>}</div> */}
          </div>
        </Slide>
        <div className="col-sm-6 section9-right-content">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/nikhil-nanda.appspot.com/o/Connect.jpg?alt=media&token=1ce5228d-00c7-43cd-a880-f42b2970212c"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Section9;
