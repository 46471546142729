import React, { useEffect } from "react";

import PageSection from "../SubComponents/PageSectionLeft";
import PhilanthropyImg from "../Assets/phil11.jpg";

import ReactPlayer from "react-player";
const Philanthropy = () => {
  const description = `### Donation and gratitude

  "One who enjoys abundant abundance without sharing it with others is really a thief, says the Bhagavad-gītā".
  
  Philanthropy is innate to Indian culture and has been one of the oldest practices in the country in India, whose history goes back thousands of years. Even if today's India does not match these ancient texts, there are people who follow the values of philanthropy. I too am trying to be amongst them.


  #### तदेतत्त्रयँ शिक्षेद् दमं दानं दयामिति (21)

  Learn three virtues - self restraint, charity, and compassion for all living beings
  
  — Brihadaranyaka Upanishad, V.ii.3,
  
  Live these verses on a daily basis in pursuit of enlightenment and you will find that life becomes a lot easier. I try to follow these lines everyday in my life. It is not so much wealth that brings happiness and peace but our attitude towards living.
  
  ### Welfare of the World 
  
  True satisfaction lies in an act that is done for the benefit of society and the nation. I am fortunate to be given the opportunity and the resources to make a difference wherever possible. The Bhagavadgita urges people to act selflessly for the welfare of others: 
  Strive constantly to serve the welfare of the world; by devotion to selfless work one attains to the supreme goal in life. Do your work with the welfare of others in mind’ (3.19-26).
  A conscious citizen is the one who understands and embodies virtues like honesty, compassion and charity in everyday life. Strive hard to ensure that life is led in a manner that you are always aware and conscious about your contribution to society as giving back is one of the best ways to fulfil one’s duties towards the betterment of the nation. 
  
  ### Seva Bharti Delhi
  
  Society is mine, and I am of the society. This philosophy is my inspiration for my role as a part of Seva Bharti Delhi which provides free medical assistance, education, and vocational training to economically weaker sections in India. This organization keeps me grounded and in touch with the reality that surrounds me. It also serves as an opportunity and inspiration through which I share my personal achievements in society with those who are deprived of it for some reason.
  
  `;
  const description2 = `### The HCN Trust and Educational Society 
  
  The HCN Trust and Educational Society was established in the name of my father Late Shri Harish Chander Nanda (IRS), for social and spiritual causes. In 2008, this trust was responsible for constructing the tallest statue of Hanuman Ji near Jakhoo Temple, Shimla. It took two years to build the statue, and it was completed in 2010. This historic statue was unveiled by then Chief Minister, Honorable Professor Prem Kumar Dhumal.
  
  ### Health Charitable trust
  
  Another humble contribution to helping those who need it most is the Health Charitable trust that was set up to provide aid and support to those who are afflicted with cancer. The trust provides financial and emotional support to cancer patients and their families. Cancer can be soul-shattering and this trust wants to alleviate the emotional pain. Giving with humanitarian intentions to those who need help at the right time and place has philosophic vision of humanism, social dimension of welfare and economic benevolence in result, in addition to producing the satisfaction of spiritual gain. 
  In order to join me in my philanthropic efforts please reach out to me through the contact page.
  
  
  As an active participant in various social causes, I have hosted and attended various national and global social events. I was present at the dinner hosted by Prime Minister Shri Narendra Modi Ji during his visit to the US in September 2014 at Taj Pierre, Manhattan. On many other occasions, I had the privilege to meet and receive blessings from our famous Prime Minister.
  
  `;

  const description3 = `
  ### दान और आभार 

“जो दूसरों के साथ साझा किए बिना प्रचुर मात्रा में आनंद लेता है, वह वास्तव में एक चोर है, भगवद गीता कहती है”।
 
परोपकार की संस्कृति भारत की सबसे पुरानी प्रथा है, जिसका इतिहास हजारों वर्षों पुराना है। भले ही आज का भारत वर्ष इन प्राचीन ग्रंथो से न मिलता हो पर आज भी कई लोग ऐसे हैं जो परोपकार के मूल्यों का पालन करते हे, मैं अपने जीवन को सिद्ध करने के लिए उन सांस्कृतिक परंपराओं को निरंतर सिद्ध करने की कोशिश रखे हुए हूँ। 
 
#### तदेतत्रयँ शिक्षेद् दमं दानं दयामिति (21)

तीन गुणों को जानें - आत्म संयम, दान और सभी जीवों के प्रति दया
- बृहदारण्यक उपनिषद, V.ii.3
 
आत्मज्ञान की खोज में दैनिक आधार पर इन छंदों का पालन करें और आप पाएंगे कि जीवन बहुत सरल हो गया है। मैं अपने जीवन में हर रोज इन पंक्तियों का पालन करने की कोशिश करता हूं। मनुष्य को धन, सुख और शांति प्रदान नहीं करता, बल्कि जीवन के प्रति हमारा दृष्टिकोण और नर सेवा से सन्तुष्टि जो इस मनुष्य जीवन को साक्षर करता है।

### विश्व का कल्याण

भगवद गीता हमें प्रेरणा देती है के हमें दूसरों के कल्याण के लिए निस्वार्थ भाव से कार्य करना चाहिए है:
दुनिया के कल्याण के लिए सब लगातार प्रयास करें; निस्वार्थ भाव से कर्म करने से जीवन में सर्वोच्च लक्ष्य की प्राप्ति होती है। दूसरों के कल्याण को ध्यान में रखकर अपना काम करें '(3.19-26)
 
एक जागरूक मनुष्य वह है जो रोज़मर्रा के जीवन में ईमानदारी, करुणा और दान जैसे गुणों को समझता है और उसे अपनाता है। जीवन में ऐसे आगे बढे की आप सदैव समाज और राष्ट्र की बेहतरी के लिए योगदान करे। सच्ची संतुष्टि एक ऐसे कार्य में निहित है जो समाज और राष्ट्र के हित के लिए किया जाता है। मैं सौभाग्यशाली हूं कि जहां भी संभव हुआ, मुझे योगदान देने का मौक़ा मिला मैंने हमेशा तत्परता से उसे पूर्ण किया । 

### सेवा भारती दिल्ली
 
नर सेवा नारायण सेवा । इस भावार्थ के साथ सेवा भारती दिल्ली के उपाध्यक्ष के दायित्व में हम सब मिल कर भारत में आर्थिक रूप से कमजोर वर्गों को मुफ्त चिकित्सा सहायता, शिक्षा और व्यावसायिक प्रशिक्षण प्रदान करते है। यह संस्था मुझे ज़मीन से जोड़ती है और मेरी प्रेरणास्रोत है जिस के द्वारा मैं समाज में अपने निजी उपलब्धियों को उन लोगों के संग बाँटता हूँ जो किसी कारणवश इससे वंचित है । 
 
 
### HCN ट्रस्ट एंड एजुकेशनल सोसाइटी
 
HCN ट्रस्ट एंड एजुकेशनल सोसाइटी की स्थापना मेरे पिता स्वर्गीय श्री हरीश चंदर नंदा (IRS) के नाम पर सामाजिक और आध्यात्मिक कार्यो के लिए की गई थी। 2008 में, इस ट्रस्ट  ने शिमला के जाखू मंदिर के पास हनुमान जी की सबसे ऊंची प्रतिमा का निर्माण कार्य शुरू करवाया। प्रतिमा को बनाने में दो साल लगे और निर्माण 2010 में सम्पूर्ण कर हमारे पूज्य पिता जी श्री हरीश चन्द्र नन्दा ऐवम तत्कालीन मुख्यमंत्री माननीय प्रोफ़ेसर प्रेम कुमार धूमल जी के कर कमलों द्वारा इस ऐतिहासिक प्रतिमा का अनावरण हुआ । 
 
### हेल्थ चेरिटेबल ट्रस्ट
 
हेल्थ चेरिटेबल ट्रस्ट को कैंसर से पीड़ित लोगों को सहायता और आर्थिक मदद प्रदान करने के लिए स्थापित किया गया था। कैंसर एक ऐसी बीमारी हे जो सिर्फ शरीर को ही नहीं बल्कि रोगियों, परिवारों और देखभाल करने वालों के भावनात्मक स्वास्थ्य को प्रभावित कर सकता है। यह ट्रस्ट कैंसर रोगियों और उनके परिवारों को वित्तीय और भावनात्मक सहायता प्रदान करती है।

मेरे परोपकारी प्रयासों में शामिल होने के लिए कृपया संपर्क पृष्ठ के माध्यम से मेरे पास पहुँचें।
 
 
विभिन्न सामाजिक कारणों में एक सक्रिय भागीदार के रूप में, मैंने विभिन्न राष्ट्रीय और वैश्विक सामाजिक आयोजनों की मेजबानी और भाग लिया है। मैं सितंबर 2014 में मैनहट्टन के ताज पियरे में अमेरिका की यात्रा के दौरान प्रधान मंत्री श्री नरेंद्र मोदी जी द्वारा आयोजित रात्रि भोज में उपस्थित था। उसके बाद भी कई विशेष अवसरों पर यह सौभाग्य प्राप्त हुआ और हमारे यशस्वी प्रधानमंत्री जी से आशीर्वचन मिले ।

  `;
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <React.Fragment>
      <div className="section-top-right">
        <h4 className="section-top-heading">PHILANTHROPY</h4>
        <div style={{ color: "#2c3e50" }}>
          "Society is mine, and I am of the society. Imbuing this philosophy, fortunate to be contributing to the society as the head of HCN Trust and Health Charitable Trust and a part of Seva Bharti Delhi, . Dedicated to make our world a better place for present and future generations to come in our country."
        </div>
        {/* <ReactPlayer
          url="https://firebasestorage.googleapis.com/v0/b/nikhil-nanda.appspot.com/o/phil.mp4?alt=media&token=faffc9f6-eaea-4aee-ae38-d0ee4bc3a62d"
          playing={true} //autoplay
          controls
          width="30%"
          style={{ margin: "40px" }}
        /> */}
      </div>
      <div className="row">
        <div className="col-sm-6">
          <ReactPlayer
            url="https://firebasestorage.googleapis.com/v0/b/nikhil-nanda.appspot.com/o/phil.mp4?alt=media&token=faffc9f6-eaea-4aee-ae38-d0ee4bc3a62d"
            playing={true} //autoplay
            controls
            width="100%"
            style={{ margin: "0px 20px 20px 20px" }}
          />
          <PageSection description={description3} />
        </div>
        <div className="col-12 col-sm-6">
          <PageSection description={description} />
          {/* <img
            src={PhilanthropyImg}
            alt="Philanthropy"
            className="InsideImagesLeft"
          /> */}
          <img
            src={PhilanthropyImg}
            alt="Philanthropy"
            className="InsideImagesLeft"
          />
          <PageSection description={description2} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Philanthropy;
