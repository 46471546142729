import React from "react";
import { Link } from "react-router-dom";
import { Navbar } from "react-bootstrap";
// import Sqaure from "../Assets/Sqaure.png";
import Logo from "../Assets/Logo.png";

import { slide as Menu } from "react-burger-menu";




const Header = () => {
  
  return (
    <React.Fragment>
      
      <Navbar bg="black" id="App1" expand="lg" className="header" sticky="top"  style={{backgroundColor:"black"}}>
        <Link to="/" >
          {/* <img
            style={{ margin: "0 40px" }}
            src={Sqaure}
            alt=""
            id="header-img"
          /> */}
          <img src={Logo} alt="" id="header-logo" />
        </Link>
        <div className="">
        <Menu pageWrapId={"page-wrap"} outerContainerId={"App"} right >
        <a className="menu-item" style={{color:"white"}} href="#whoami">
        <p className="lead font-weight-bold" style={{color:"white"}}>
        WHO AM I
        </p>
      </a>

      <a className="menu-item" style={{color:"white"}} href="#myfocus">
        <p className="lead font-weight-bold" style={{color:"white"}}>
        FOCUS
        </p>
      </a>
      <a className="menu-item" style={{color:"white"}} href="#milestones">
        <p className="lead font-weight-bold" style={{color:"white"}}>
        MILESTONES
        </p>
      </a><a className="menu-item" style={{color:"white"}} href="#blogs">
        <p className="lead font-weight-bold" style={{color:"white"}}>
        WRITINGS
        </p>
      </a><a className="menu-item" style={{color:"white"}} href="#contact">
        <p className="lead font-weight-bold" style={{color:"white"}}>
        CONNECT
        </p>
      </a>
    </Menu>
    </div>
          {/* <div className="try2">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav className="ml-auto">
            <Nav.Link>
              <SLink to="whoami" smooth={true} duration={2000}>
                WHO AM I?
              </SLink>
            </Nav.Link>
            <Nav.Link>
              <SLink to="myfocus" smooth={true} duration={2000}>
                FOCUS
              </SLink>
            </Nav.Link>
            <Nav.Link>
              <SLink to="milestones" smooth={true} duration={2000}>
                MILESTONES
              </SLink>
            </Nav.Link>
            <Nav.Link>
              <SLink to="blogs" smooth={true} duration={2000}>
                WRITNGS
              </SLink>
            </Nav.Link>
            <Nav.Link>
              <SLink to="contact" smooth={true} duration={2000}>
                CONNECT
              </SLink>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
        
</div> */}
      </Navbar>
    </React.Fragment>
  );
};

export default Header;
