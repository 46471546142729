import React, { useEffect } from "react";

import PageSection from "../SubComponents/PageSectionLeft";
import BusinessImg from "../Assets/bus11.jpg";

import ReactPlayer from "react-player";
const Business = () => {
  const description = `Rooted deep in my heart is the ambition to excel at every venture I undertake. The philosophy of swadeshi has been a guiding principle in all opportunities that I undertook. As a serial entrepreneur, all my ventures have had India-First as a core value.

  JHS-Svendgaard Lab Ltd., a prime oral care product manufacturer was founded in 1996, and today it is the largest manufacturer of toothbrushes in the country. Adopting the latest technology is the bedrock on which the establishment is built. Launched in 1996, the company has grown into a vibrant and dynamic force in its sector, serving many Indian and international brands. JHS Svendgaard Laboratories Limited offers oral care products (toothbrushes, toothpaste, mouthwash, whitening gels and denture products) for national and global FMCG brands. The Company also manufactures laundry products. JHS Svendgaard Dental Care Limited and JHS Mechanical & Warehouse Private Limited are its subsidiaries. Even though the company began as a toothbrush manufacturer, JHS has expanded to include many other verticles. JHS also runs T32Dental Spa clinics across the country. These spas are a complete dental care enterprise. JHS today manufactures many other products like detergent, toothpaste, mouthwash etc. for many national and international brands. 
  `;

  const description2 = `#### As a big believer in Swadeshi, 
  I have wholeheartedly endorsed the movement that saw resurgence by one of its biggest proponents in modern times - Baba Ramdev. Under his leadership and vision, his company Patanjali is challenging the supremacy of the multinationals in India. He has brought an unprecedented transformation in the FMCG sector in India by taking multinational giants' heads on and building a company that ranks as India's second-largest FMCG company. The company is also poised to take the numero uno position in the coming years.
  
  JHS Sveengard is very fortunate to get the opportunity to work closely with Baba Ramdev Ji, who showed the world that age-old local remedies could be translated into excellent health products for the present age. Looking inwards is the new mantra, and with the same spirit, we set up Patanjali outlets at all International airports in the country.
  `;

  const description3 = `
  
#### एक उद्यमी के रूप में, मेरे सभी व्यवसायों में भारत-प्रथम रहा है।


मेरा पहला उद्यम JHS-Svendgaard Lab Ltd., एक प्राइम ओरल केयर प्रदाता, 1996 में शुरू हुआ और आज यह कम्पनी देश में टूथब्रश का सबसे बड़ी निर्माता है। नवीनतम तकनीक को अपनाना इस  प्रतिष्ठान का आधार रहा है। भले ही यह कंपनी टूथब्रश निर्माता के रूप में शुरू हुई, लेकिन आज JHS राष्ट्रीय और वैश्विक FMCG ब्रांडों के लिए ओरल केयर उत्पाद (टूथब्रश, टूथपेस्ट, माउथवॉश, व्हाइटनिंग जैल और डेंटल प्रोडक्ट्स) प्रदान करती है।। यह कम्पनी  देश भर में T32Dental Spa क्लीनिक भी चलाती  है। ये स्पा एक पूर्ण दंत चिकित्सा देखभाल की जगह हैं।

मेने स्वदेशी आंदोलन का पूरे दिल से समर्थन किया है। स्वदेशी आंदोलन के इस जगत में सबसे बड़े समर्थक हैं प्रसिद्ध योग प्रशिक्षक स्वामी रामदेव जिनके नेतृत्व में उनकी कंपनी पतंजलि भारत में बहुराष्ट्रीय कंपनियों के वर्चस्व को चुनौती दे रही है। उन्होंने भारत में एफएमसीजी क्षेत्र में एक अभूतपूर्व परिवर्तन लाया है और एक ऐसी कंपनी का निर्माण किया है जो भारत की दूसरी सबसे बड़ी एफएमसीजी कंपनी के रूप में रैंक कर रही है।

बाबा रामदेव जी के साथ मिलकर काम करने का अवसर पाने के लिए मैं खुद को बहुत भाग्यशाली मानता हूं, बाबा रामदेव जी ने दुनिया को दिखाया कि वर्तमान जग में भी प्राचीन भारतीय उपचारों का उत्कृष्ट स्वास्थ्य उत्पादों में अनुवाद किया जा सकता है। स्वावलंबन नया मंत्र है, और इसी भावना के साथ, हम देश के सभी हवाई अड्डों पर पतंजलि आउटलेट स्थापित करते हैं। पहले आउटलेट का उद्घाटन 18 जनवरी, 2018 को इंदिरा गांधी अंतर्राष्ट्रीय हवाई अड्डे के टी 2 टर्मिनल पर किया गया था और आज देश भर में चार से अधिक आउटलेट हैं।

  `;
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <React.Fragment>
      <div className="section-top-right">
        <h4 className="section-top-heading">INDUSTRIALIST</h4>
        <div style={{ color: "#2c3e50" }}>
          "With a vision to make India a world leader in oral care products, JHS-Svendgaard was founded in 1996. Since then, we have contributed significantly in developing the oral care products industry and we are humbled to have been one of the top players in the domain today."
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-sm-4 SmallSizeDiv">
          <img src={BusinessImg} alt="Business" className="InsideImagesRight" />
        </div>
        <div className="col-12 col-sm-6">
          <PageSection description={description} />
          <img src={BusinessImg} alt="Business" className="InsideImagesRight" />
          <PageSection description={description2} />
        </div>
        <div className="col-12 col-sm-6">
          <ReactPlayer
            url="https://firebasestorage.googleapis.com/v0/b/nikhil-nanda.appspot.com/o/business.mp4?alt=media&token=ffa7a3f7-a1d0-4862-844d-0a0b7b7ed476"
            width="100%"
            style={{ margin: "1px 0px 10px 0px" }}
            playing={true} //autoplay
            controls
          />
          <PageSection description={description3} />
          {/* <img src={BusinessImg} alt="Business" className="InsideImagesRight" /> */}
        </div>
        {/* <ReactPlayer
          url="https://firebasestorage.googleapis.com/v0/b/nikhil-nanda.appspot.com/o/business.mp4?alt=media&token=ffa7a3f7-a1d0-4862-844d-0a0b7b7ed476"
          width="100%"
          style={{ margin: "40px" }}
          playing={true} //autoplay
          controls
        /> */}
      </div>
    </React.Fragment>
  );
};

export default Business;
