import React from "react";
import { Link } from "react-router-dom";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Section2CardsLeft = ({ title, description, color, link, imgsrc }) => {
  return (
    <div className="section2-card row text-sm-right">
      <div className="col-sm-4 section2-icon">
        {/* <FontAwesomeIcon icon={icon} size="10x" /> */}
        <img className="FourCardImage" src={imgsrc} alt="" />
      </div>
      <div className="col-sm-4 section2-icon-mobile">
        {/* <FontAwesomeIcon icon={icon} size="5x" /> */}
        <img className="FourCardImage" src={imgsrc} alt="" />
      </div>
      <div className="col-12 col-sm-8">
        <h4 className="section2-card-heading" style={{ color: `${color}` }}>
          {title}
        </h4>
        <p className="section2-card-paragraph">"{description}"</p>
        <button
          className="section2-card-button"
          style={{ backgroundColor: `${color}`, color: "#fff", border: "none" }}
        >
          <Link to={link}>Read More</Link>
        </button>
      </div>
    </div>
  );
};

export default Section2CardsLeft;
