import React from "react";

import Section8Carousel from "../SubComponents/Carousel";

const Section8 = () => {
  return (
    <React.Fragment>
      <div className="section-top">
        <h4 className="section-top-heading">MEDIA COVERAGE</h4>
        <div style={{ color: "#2c3e50" }}>
          Read more about our latest events, campaigns and activity
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-sm-6 section8-left-section">
          <img id="FlagImage" alt="" src="https://images.unsplash.com/photo-1532375810709-75b1da00537c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60"></img>
        </div>
        <div className="col-12 col-sm-6" style={{ backgroundColor: "#2c3e50" }}>
          <Section8Carousel />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Section8;
