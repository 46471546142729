import React from "react";

import Fade from "react-reveal/Fade";

const Section5 = () => {
  return (
    <React.Fragment>
      <div className="section-top">
        <h4 className="section-top-heading">ABOUT ME</h4>
        <div style={{ color: "#2c3e50" }}>I believe that running an enterprise and nation-building are inseparable instruments of progress.</div>
      </div>
      <div className="row">
        <div
          className="col-12 col-sm-6"
          style={{ backgroundColor: "#2C3E50", color: "#fff" }}
        >
          <Fade left>
            <h4 className="section5-heading">MY VISION FOR THE NATION</h4>
            <div className="section5-border-left"></div>
            <div className="section5-content">
            Equality for all without appeasement politics equates my vision for the nation. I am against those who pitch one community against the other and have been conferring preferential treatment to one community over others. Within the broad framework of one nation and one code, I favour the institutions that protect the Hindu identity of the nation that encompasses every citizen. I align with those who are disrupting the colonial mindset and are working towards ending divisive politics on the lines of religion, caste and creed that is the remnant of the colonial era and was carried forward by the Congress. 
Shri Narendra Modi Ji has been the unequivocal choice for the nation since 2014, who with his visionary and nation first policy has been able to transform India into a vibrant, patriotic and prosperous nation. Under his leadership, Emerging India sees a new dawn. The innate desire of billion-plus Indians to see their country sans hunger, poverty, corruption, nepotism and terrorism is on the verge of being fulfilled. As we stand at the cusp of history, we are transforming into a nation of billion sparks that are waiting to ignite a fire of unprecedented growth.
            </div>
          </Fade>
        </div>

        <div
          className="col-12 col-sm-6"
          style={{
            background: "linear-gradient(0deg, #D5820D, #D5820D), #C06A2B",
            color: "#fff",
          }}
        >
          <Fade right>
            <h4 className="section5-heading text-right">NATIONALISM</h4>
            <div className="section5-border-right"></div>
            <div className="section5-content">
            I see nationalism through the prism of Hinduism that has bound the country like a string of pearls. The virtues of tolerance in Hinduism allowed all the faiths to thrive and prosper in the ancient land of India whose diversity is also a by-product of the essence of the Hindu way of life which is accommodative, open-minded and progressive. Free of dogmas and tenets, Hinduism lays the path for the comprehensive development of the nation. I am of the belief that Nationalism can bind the citizens of the country in a common thread and together we can address the problems faced by society and find solutions to stay together, grow together. 
The Hindu youth has excelled in all the fields all over the world. From dominating the Silicon Valley in the US to excelling in sports and leading from the front abilities of the Indian army's newly commissioned officers, the young Hindus have become a symbol of India's success. I believe that with nationalism and devotion, India's Youth can become a beacon of change for the world. Their progress shows that the future of India is not only secure but shining.
            </div>
          </Fade>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Section5;
