import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./Components/Header";
import Home from "./Pages/Home";
import Hinduism from "./Pages/Hinduism";
import Philanthropy from "./Pages/Philanthropy";
import Business from "./Pages/Business";
import Fitness from "./Pages/Fitness";
import NotFound from "./Pages/NotFound";
import BlogList from "./Blog/BlogList";
import SingleBlog from "./Blog/SingleBlog";
import Footer from "./Components/Footer";
import Contact from "./Pages/Contact";
import NewsList from "./News/NewsList";
import SingleNews from "./News/SingleNews";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  useEffect(() => {
    setTimeout(function () {
      setLoader(true);
    }, 1900);
  });
  const [loader, setLoader] = React.useState(false);
  return (
    <React.Fragment>
      {loader === false && (
        <div id="loaderOutDiv" >
          <div id="loader"></div>
        </div>
      )}
      {loader === true && (
        <div id="App" >
          <Header  />
          <div id="page-wrap">
          <div id="bodyAfterNav">
            <ToastContainer />
            <Switch>
              <Route path="/hinduism" component={Hinduism} />
              <Route path="/philanthropy" component={Philanthropy} />
              <Route path="/business" component={Business} />
              <Route path="/fitness-enthusiast" component={Fitness} />
              <Route path="/not-found" component={NotFound} />
              <Route path="/" exact component={Home} />
              <Route path="/blog" exact component={BlogList} />
              <Route path="/contact" exact component={Contact} />
              <Route
                exact
                path="/blog/:postid"
                render={(props) => <SingleBlog {...props} />}
              />
              <Route path="/news" exact component={NewsList} />
              <Route
                exact
                path="/news/:postid"
                render={(props) => <SingleNews {...props} />}
              />
              <Redirect to="/not-found" />
            </Switch>
            <Footer />
          </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default App;
