import React, { Component } from "react";
import axios from "axios";
import { toast } from "react-toastify";

class Contact extends Component {
  state = {
    name: "",
    email: "",
    subject: "",
    message: "",
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { name, email, subject, message } = this.state;

    try {
      await axios.post("https://usebasin.com/f/04d214d520f7", {
        name,
        email,
        subject,
        message,
      });
      toast.success("Message successfully sent.");
      setTimeout(() => {
        window.location = "/";
      }, 2000);
    } catch (error) {
      toast.error("Something went wrong.");
    }
  };
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <React.Fragment>
        <div className="section-top">
          <h4 className="section-top-heading">CONNECT WITH US</h4>
          <div style={{ color: "#2c3e50" }}></div>
        </div>
        <form className="contact-form" onSubmit={this.handleSubmit}>
          <div class="form-group">
            <div className="row">
              <div className="col-sm-6 contact-form-fields">
                <input
                  type="text"
                  class="form-control"
                  name="name"
                  placeholder="Enter name"
                  onChange={this.handleChange}
                  required
                />
              </div>
              <div className="col-sm-6 contact-form-fields">
                <input
                  type="email"
                  class="form-control"
                  name="name"
                  placeholder="Enter email"
                  onChange={this.handleChange}
                  required
                />
              </div>
            </div>
            <div className=" contact-form-fields">
              <input
                type="text"
                class="form-control"
                name="subject"
                placeholder="Enter subject"
                onChange={this.handleChange}
                required
              />
            </div>
            <div className=" contact-form-fields">
              <textarea
                class="form-control"
                name="message"
                placeholder="Enter your mesaage"
                rows="5"
                onChange={this.handleChange}
                required
              />
            </div>
          </div>
          <div className="text-center">
            <button type="submit" class="contact-form-button">
              Submit
            </button>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

export default Contact;
