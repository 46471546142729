import React from "react";

import Section1 from "../Components/Section1";
import Section2 from "../Components/Section2";
import Section3 from "../Components/Section3";
import Section4 from "../Components/Section4";
import Section5 from "../Components/Section5";
import Section6 from "../Components/Section6";
import Section7 from "../Components/Section7";
import Section8 from "../Components/Section8";
import Section9 from "../Components/Section9";
import Section10 from "../Components/Section10";
import Social from "../Components/Social";
const Home = () => {
  return (
    <React.Fragment>
      <Section1 />
      <Section3 />
      <Section2 />
      <Section4 />
      <Section6 />
      <Section5 />
      <Section7 />
      <Section8 />
      <Section10 />
      <Section9 />
      <Social/>
    </React.Fragment>
  );
};

export default Home;
