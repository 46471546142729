import React, { Component } from "react";
import ReactMarkdown from "react-markdown";
import axios from "axios";
import { Grid } from "@material-ui/core";
class SingleNews extends Component {
  constructor() {
    super();
    this.state = {
      SingleNews: [],
    };
  }

  async componentDidMount() {
    const { data } = await axios.get(
      `https://nikhil-mongo-strapi.herokuapp.com/our-news/${this.props.match.params.postid}`
    );

    this.setState({ SingleNews: data });
  }

  render() {
    return (
      <div>
        <Grid container>
          <Grid item xs={1} />
          <Grid item xs={10}>
            <div id="BlogTop"></div>
            <div id="BlogTitle">
              <h1>{this.state.SingleNews.title}</h1>
            </div>
            <div id="BlogSubHeading">
              <h3>{this.state.SingleNews.subheading}</h3>
            </div>

            <div id="BlogContent">
              <ReactMarkdown source={this.state.SingleNews.content} />
            </div>
          </Grid>
          <Grid item xs={1} />
        </Grid>
        <div id="BlogBottom"></div>
      </div>
    );
  }
}

export default SingleNews;
