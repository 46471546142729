import React from "react";
// import Photo from "../Assets/final_landing.png";
import Slide from "react-reveal/Slide";
import Fade from "react-reveal/Fade";
// import Pic2 from "../Assets/Pic2.png";

const Section1 = () => {
  return (
    <div className="blackBgSection1">
      <div className="section1-img-div">
        <Slide bottom>
          {/* <img src={Photo} id="section1-img" alt="" /> */}
        </Slide>
      </div>

      <h1 className="section1-text">
        <div className="row">
          <div className="col-6 try3">
            <span style={{ color: "#fe7122" }}>
              <Fade left>Nikhil</Fade>
            </span>
          </div>
          <div className="col-6 try4">
            <span style={{ color: "#fe7122" }}>
              <Fade right>Nanda</Fade>
            </span>
          </div>
        </div>
      </h1>
      {/* <h1 className="section1-text mobile">
        <span>Nikhil</span>
        <span>Nanda</span>
      </h1> */}
      {/* <h1 className="section1-text mobile">
        <span>Nikhil</span>
        <span>Nanda</span>
      </h1> */}
      {/* <h1 className="section1-text">
      <div className="row"><div className="col-6 try3"><span >
        
        Nikhil</span></div>
    <div className="col-6 try4"><span  >
      
      Nanda</span></div></div>
      </h1> */}
      {/* <h1 className="section1-text mobile">
        <span>Nikhil</span>
        <span>Nanda</span>
      </h1> */}
      {/* <h1 className="section1-text">
        <span>Nikhil</span>
        <span>Nanda</span>
      </h1> */}
      {/* <h1 className="section1-text mobile ">
      
      <div className="row"><div className="col-6 try3"><span >
        
          Nikhil</span></div>
      <div className="col-6 try4"><span  >
        
        Nanda</span></div></div>
          
      </h1> */}
    </div>
  );
};

export default Section1;
