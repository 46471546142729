import React from "react";
import fetch from "isomorphic-fetch";
import { Grid } from "@material-ui/core";
import NewsCard from "./NewsCard";
import Slide from "react-reveal/Slide";

class NewsList extends React.Component {
  constructor() {
    super();
    this.state = {
      news: [],
    };
  }

  componentWillMount() {
    fetch("https://nikhil-mongo-strapi.herokuapp.com/our-news")
      .then((response) => {
        if (response.status >= 400) {
          throw new Error("Bad Response from Server");
        }
        return response.json();
      })
      .then((news) => {
        this.setState({ news: news });
      });
  }
  render() {
    return (
      <div>
        <Grid container>
          <Grid item xs={1} />
          <Grid item xs={10}>
            <div id="BlogTop"></div>
            <Grid container spacing={4}>
              {this.state.news
                .reverse()
                .map(({ id, title, subheading, DisplayImage }) => (
                  <Grid key={id} item xs={12} md={6}>
                    <Slide bottom>
                    <NewsCard
                      url={`/news/${id}`}
                      title={title}
                      subheading={subheading}
                      imgUrl={`https://nikhil-mongo-strapi.herokuapp.com${DisplayImage.formats.small.url}`}
                    />
                    </Slide>
                  </Grid>
                ))}
              <div style={{marginBottom:"25rem"}}></div>
            </Grid>
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </div>
    );
  }
}

export default NewsList;
