import React, { Component } from "react";
import ReactMarkdown from "react-markdown";
import axios from "axios";
import { Grid } from "@material-ui/core";
class SingleBlog extends Component {
  constructor() {
    super();
    this.state = {
      SingleBlog: [],
      test: "",
    };
  }

  async componentDidMount() {
    const { data } = await axios.get(
      `https://nikhil-mongo-strapi.herokuapp.com/blogs/${this.props.match.params.postid}`
    );

    this.setState({ SingleBlog: data });
    // this.setState({
    //   picture1: data.picture_first.length
    //     ? data.picture_first.map((pic) => {
    //         return pic.url;
    //       })
    //     : "0",
    // });
    // this.setState({
    //   picture2: data.picture_second.length
    //     ? data.picture_second.map((pic) => {
    //         return pic.url;
    //       })
    //     : "0",
    // });
    // this.setState({
    //   picture1: data.picture_first.length
    //     ? data.picture_first.map((pic) => {
    //         return pic.url;
    //       })
    //     : "0",
    // });
    // this.setState({
    //   picture2: data.picture_second.length
    //     ? data.picture_second.map((pic) => {
    //         return pic.url;
    //       })
    //     : "0",
    // });
  }

  render() {
    return (
      <div>
        <Grid container>
          <Grid item xs={1} />
          <Grid item xs={10}>
            <div id="BlogTop"></div>
            <div id="BlogTitle">
              <h1>{this.state.SingleBlog.title}</h1>
            </div>
            <div id="BlogSubHeading">
              <h3>{this.state.SingleBlog.subheading}</h3>
            </div>

            <div id="BlogContent">
              <ReactMarkdown source={this.state.SingleBlog.content} />
            </div>
          </Grid>
          <Grid item xs={1} />
        </Grid>
        {/* {this.state.picture1 !== "0" && (
          <p>
            <img
              src={`http://localhost:1337${this.state.picture1}`}
              alt=""
            ></img>
          </p>
        )}

        <ReactMarkdown source={this.state.SingleBlog.content_first} />
        {this.state.picture2 !== "0" && (
          <p>
            <img
              src={`http://localhost:1337${this.state.picture2}`}
              alt=""
            ></img>
          </p>
        )} */}
        <div id="BlogBottom"></div>
      </div>
    );
  }
}

export default SingleBlog;
