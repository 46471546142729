import React from "react";

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faTrophy,
  faTooth,
  faGopuram,
  faWallet,
  faStar,
  faSubway,
  faCheckDouble,
  faPlane,
} from "@fortawesome/free-solid-svg-icons";

const events = [
  {
    event: "Launched JHS Svendgaard in 1996: Today it is the largest manufacturer of toothbrushes in the country. It is the only integrated facility offering comprehensive oral care solutions to the industry and has always remained debt free.",
    icon: faBriefcase,
  },
  {
    icon: faTooth,
    event: "Top Exporter Award, Five Years in a Row: JHS bagged the top exporter award in Toothbrush category from the Plastics Export Promotion Council Ministry of Commerce and Industry Government of India, for 5 years in a row from the year 2003-04 onwards.",
  },
  {
    icon: faWallet,
    event: "Successful IPO for JHS in September 2006: The company got listed on the National Stock Exchange and Bombay Stock Exchange in the year 2006. The issue was oversubscribed 2.5 times clearly witnessing a strong and healthy investor's response.",
  },
  {
    icon: faStar,
    event: "Best Performing Enterprise Award (25-100 crores category) in 2009: JHS was 'Runner-Up at Plasticz Awards 2009 for complete range of oral care products.",
  },
  {
    icon: faGopuram,
    event: "Blessed to have the privilege of building Hanuman ji statue at jakhoo mandir : The statue stands taller than the statue of Christ the Redeemer at Rio De Janeiro, Brazil and is visited by thousands of devotees every month who come to the place to seek Hanuman Ji's blessings. Construction began in 2008 and completed two years later.",
  },
  {
    icon: faTooth,
    event: "Launched First of its kind Dental Spas in India: A unique concept spas that catered to dental hygiene of people were launched across the country under the Brand name T32 dental Spas.",
  },
  {
    icon: faTrophy,
    event:
      "Featured as a Young Turk in a CNBC list of first generation entrepreneurs: Since 2002, Young Turks program by CNBC has showcased and recognised the extraordinary work of budding business leaders and has brought its viewers inspiring stories of entrepreneurship.",
  },
  {
    icon: faCheckDouble,
    event: "Awarded the certificate of excellence in recognition of exemplary growth in 2012 by One India, Indian edition of Inc., the leading US magazine.",
  },
  {
    icon: faStar,
    event: "Awarded entrepreneur of the year award in 2013: By Sh. T. K.A Nair, principal advisor to the then Prime Minister Dr Manmohan Singh, and the chairman of Bank of India Smt. R. Iyer.",
  },
  {
    icon: faTooth,
    event: "Launched Aquawhite - JHS’s own proprietary brand: In 2013, when the going was tough, JHS’s own proprietary brand Aquawhite was launched.",
  },
  {
    icon: faSubway,
    event:
      "Started JV with a UK based company to develop OBCM for Indian Railways. On Board Condition Monitoring Systems (OBCMS) is the one-stop solution for preventing onset of bearing failures and detection of faults on the tracks to ensure safe rides for the passengers",
  },
  {
    icon: faCheckDouble,
    event:
      "Presented the OBCM system to Prime Minister Narendra Modi at Rail Shivir on 18 November 2016 and 11 May 2018.",
  },
  {
    icon: faPlane,
    event:
      "Started JV with Patanjali in 2018 JHS Retail operates four Patanjali stores across Delhi and Raipur, Chhattisgarh airports. The largest store spread over 1000 sq. ft., is at IGI, New Delhi - T3 (International Departures).",
  },
];

const Section4 = () => {
  return (
    <div id="milestones">
      <div className="section-top">
        <h4 className="section-top-heading">MILESTONES</h4>
        <div style={{ color: "#2c3e50" }}>
          {" "}
          I have been blessed to live a life that encompasses many personal and
          professional achievements.
        </div>
      </div>
      <div className="section4">
        <VerticalTimeline>
          {events.map((event) => (
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              iconStyle={{
                background: "rgb(213, 130, 13)",
                color: "linear-gradient(0deg, #D5820D, #D5820D), #C06A2B;",
              }}
              icon={<FontAwesomeIcon icon={event.icon} size="lg" />}
            >
              <h5>{event.event}</h5>
            </VerticalTimelineElement>
          ))}
        </VerticalTimeline>
      </div>
    </div>
  );
};

export default Section4;
