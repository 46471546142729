import React from "react";
import Fade from "react-reveal/Fade";
import ReactMarkdown from "react-markdown";
const PageSection = ({ description }) => {
  return (
    <div className="page-section text-left">
      <Fade duration={3000}>
      <ReactMarkdown source={description} />
        {/* <p>{description}</p> */}
      </Fade>
    </div>
  );
};

export default PageSection;
