import React, { useEffect } from "react";

const Section10 = () => {
  useEffect(() => {
    var i,
      e,
      d = document,
      s = "script";
    i = d.createElement("script");
    i.async = 1;
    i.src = "https://widget.taggbox.com/embed.min.js";
    e = d.getElementsByTagName(s)[0];
    e.parentNode.insertBefore(i, e);
  });

  return (
    <div>
      <div className="section-top">
        <h4 className="section-top-heading">SOCIAL MEDIA</h4>
        <div style={{ color: "#2c3e50" }}>Connect with me.</div>
      </div>
	  <div className="taggbox-container" style={{width:"100%", height: "100%", overflow:"auto"}}><div className="taggbox-socialwall" data-wall-id="43306" view-url="https://app.taggbox.com/widget/e/43306">  </div></div>
    </div>
  );
};

export default Section10;
