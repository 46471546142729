import { Grid } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";

const BlogCard= ({ url, title, subheading, imgUrl }) => {
  return (
    <>
    <Grid conatiner id="CardForBlog" className="common-card">
      <Grid item xs={5} id="BlogImageDiv"><img
          //src="https://images.unsplash.com/photo-1593642634443-44adaa06623a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60"
          src={imgUrl}
          alt=""
          className="common-card-img"
          id="BlogImage"
        /></Grid>
      <Grid item xs={7} md={6}>
      <Grid conatiner>
      <Grid item xs={12}><h4 id="BlogCardTitle" className="common-card-heading">
          <p>{title}</p>
        </h4></Grid>
       <Grid item xs={12} id="BlogCardSubHeading" className="common-card-paragraph"> 
      
          <p>{subheading}</p>
          </Grid>
      <Grid item xs={12}>
      <Link to={url}>
          <button className="common-card-button float-right">Read More</button>
        </Link></Grid>
      
      </Grid>
      </Grid>

    </Grid>
    </>
  );
};

export default BlogCard;


// import React from "react";
// import { Link } from "react-router-dom";

// const BlogCard = ({ url, title, subheading, imgUrl }) => {
//   return (
//     <div id="CardForBlog" className="common-card">
//       <div id="BlogImageDiv">
//         <img
//           //src="https://images.unsplash.com/photo-1593642634443-44adaa06623a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60"
//           src={imgUrl}
//           alt=""
//           className="common-card-img"
//           id="BlogImage"
//         />
//       </div>
//       <div>
//         <h4 id="BlogCardTitle" className="common-card-heading">
//           {title}
//         </h4>
//         <div id="BlogCardSubHeading" className="common-card-paragraph">
//           {subheading}
//         </div>
//         <Link to={url}>
//           <button className="common-card-button float-right">Read More</button>
//         </Link>
//       </div>
//     </div>
//   );
// };

// export default BlogCard;
