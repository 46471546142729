import React from "react";
import Section2CardsLeft from "../SubComponents/Section2CardsLeft";
import Section2CardsRight from "../SubComponents/Section2CardsRight";

// import {
//   faUserTie,
//   faDumbbell,
//   faDonate,
//   faPrayingHands,
// } from "@fortawesome/free-solid-svg-icons";

import Fitness from "../Assets/fitness.png";
import Flip from "react-reveal/Flip";
import Philan from "../Assets/philan.png";
import Business from "../Assets/business.png";
import Hinduism from "../Assets/hinduism.png";

const Section2 = () => {
  const description1 =
    "A way of leading life, Sanskar (direction of Hindu dharma) is more than just a guiding light, it is the essence of being that determines life, from daily routine to major business decisions, adherence to sanskar is essential for a meaningful existence, and to leave a mark on society.";
  const description2 =
    "With a vision to make India a world leader in oral care products, JHS-Svendgaard was founded in 1996. Since then, we have contributed significantly in developing the oral care products industry and we are humbled to have been one of the top players in the domain today.";
  const description3 =
    "Society is mine, and I am of the society. Imbuing this philosophy, fortunate to be contributing to the society as the head HCN Trust and Health Charitable Trust and a part of Seva Bharti Delhi. Dedicated to make our world a better place for present and future generations to come in our country.";
  const description4 =
    "One can’t achieve anything in life if health isn't on your side. Fitness is the core to achieving discipline, success and a holistic wellbeing. Exercising is what truly makes age just a number. For me, my fitness gives me great pleasure, and I have been an enthusiast since my college days.";

  return (
    <div className="section-2" id="myfocus">
      <div className="section-top">
        <h4 className="section-top-heading">MY FOCUS</h4>
        <div style={{ color: "#2c3e50" }}>
        As a family man, self made Industrialist and philanthropist, 
        I find my inspiration in nationalism and consider the essence 
        of Sanskar (direction of Hindu dharma) integral 
        to a meaningful existence.
        </div>
      </div>
      <div className="row section7-cards">
        <div className="col-12 col-sm-6">
          <Flip top>
            <Section2CardsLeft
              title="SANSKAR"
              description={description1}
              color="#2C3E50"
              link="/hinduism"
              // icon={faPrayingHands}
              imgsrc={Hinduism}
            />
          </Flip>
        </div>
        <div className="col-12 col-sm-6">
          <Flip top>
            <Section2CardsRight
              title="PHILANTHROPIST"
              description={description3}
              color="#d5820d"
              link="/philanthropy"
              // icon={faDonate}
              imgsrc={Philan}
            />
          </Flip>
        </div>
      </div>
      <div className="row section7-cards">
        <div className="col-12 col-sm-6">
          <Flip top>
            <Section2CardsLeft
              title="INDUSTRIALIST"
              description={description2}
              color="#d5820d"
              link="/business"
              // icon={faUserTie}
              imgsrc={Business}
            />
          </Flip>
        </div>
        <div className="col-12 col-sm-6">
          <Flip top>
            <Section2CardsRight
              title="FITNESS ENTHUSIAST"
              description={description4}
              color="#2C3E50"
              link="/fitness-enthusiast"
              // icon={faDumbbell}
              imgsrc={Fitness}
            />
          </Flip>
        </div>
      </div>
    </div>
  );
};

export default Section2;
