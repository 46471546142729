import React from "react";

import { Carousel } from "react-bootstrap";

const Section8Carousel = () => {
  return (
    <Carousel>
      <Carousel.Item>
        <div className="carousel-heading">Nikhil Nanda with param pujya Baba Ramdev ji</div>
        <div className="carousel-content">
        Nikhil Nanda with param pujya Baba Ramdev ji, talking about yoga and its benefits, at the International Day of Yoga celebrations, organized by Consulate General of India, Birmingham in association with Patanjali Yog Peeth (UK) Trust... <a href="https://nikhilnanda.com/news/5f85d2bf0d4d0c0017757aff">Read More</a>
            
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className="carousel-heading">Modi is the best India has had.</div>
        <div className="carousel-content">
        In two months from now, the Modi government will complete four years in office. 
        Before one goes into a performance audit, it is important to take note of the manner in which the political situation is unfolding... <a href="https://nikhilnanda.com/news/5f86d1799605c90017e8c1db">Read More</a>
        
        </div>
      </Carousel.Item>
      {/* <Carousel.Item>
        <div className="carousel-heading">LOREM IPSUM</div>
        <div className="carousel-content">
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Veritatis
          molestiae voluptate totam porro modi distinctio excepturi! Tenetur a
          quod possimus veniam rerum ipsa quaerat recusandae itaque dolor, eos
          molestias asperiores nulla officiis quasi rem id vel, saepe harum
          delectus quos alias aliquid nam, aliquam cum. Dolor laboriosam impedit
          neque nobis?
          <div>
            <Link to="/news">Read More</Link>
          </div>
        </div>
      </Carousel.Item> */}
    </Carousel>
  );
};

export default Section8Carousel;